.upload-button {
    display: block;
    width: auto;
    outline: none;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    background: #18a689;
    color: #FFFFFF;
    text-align: center;
    padding: 0.5em 1em;
    border: 1px solid #18a689;
    cursor: pointer;
}

.margin-bottom {
    margin-bottom: 1em;
}
