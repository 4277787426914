/* Generated by Glyphter (http://www.glyphter.com) on  Thu Mar 31 2016*/
@font-face {
    font-family: 'icon_modalku';
    src: url('fonts/modalku.eot');
    src: url('fonts/modalku.eot?#iefix') format('embedded-opentype'),
         url('fonts/modalku.woff') format('woff'),
         url('fonts/modalku.ttf') format('truetype'),
         url('fonts/modalku.svg#modalku') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='icon-']:not([class*='fsw-icon']):before{
	display: inline-block;
   font-family: 'icon_modalku';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

.icon-icon_modalku_fs:before{content:'\0042';}
.icon-icon_modalku_pemodal:before{content:'\0043';}
.icon-icon_modalku_peminjam:before{content:'\0044';}
.icon-icon_modalku_sign:before{content:'\0045';}
.icon-icon_modalku_ontime:before{content:'\0046';color: rgb(115, 182, 32)}
.icon-icon_modalku_late:before{content:'\0046';color: rgb(198, 198, 0)}
.icon-icon_modalku_partial:before{content:'\0047';color: rgb(201, 74, 74)}
.icon-icon_modalku_dueunpaid:before{content:'\0048';color: #555}
.icon-icon_modalku_unpaid:before{content:'\0048'; color: #ececec}
.icon-icon_modalku_pinjaman:before{content:'\0049';}
.icon-icon_modalku_financing:before{content:'\004a';}

.icon-pembayaran_tepat_waktu:before{
  color: rgb(115, 182, 32);
  content:'\0046';
}
.icon-pembayaran_terlambat:before{
  color: rgb(228, 228, 1);
  content:'\0046';
}
.icon-pembayaran_parsial:before{
  color: rgb(201, 74, 74);
  content:'\0047';
}
.icon-pembayaran_tertunda:before{
  color: #555;
  content:'\0048';
}
