.pantry html,
.pantry body {
  margin: 0;
  padding: 0;
}
.pantry table,
.pantry caption,
.pantry tbody,
.pantry tfoot,
.pantry thead,
.pantry tr,
.pantry th,
.pantry td {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
.pantry table {
  border-collapse: collapse;
  border-spacing: 0;
}
.pantry fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}
.pantry legend {
  padding: 0;
}
.pantry ::placeholder {
  color: #9b9b9b;
}
.pantry :disabled::placeholder {
  color: #adadad;
}
.pantry input::-webkit-outer-spin-button,
.pantry input::-webkit-inner-spin-button {
  margin: 0;
  appearance: none;
}
.pantry html {
  box-sizing: border-box;
}
.pantry *,
.pantry *::before,
.pantry *::after {
  box-sizing: inherit;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://cdn.fundingasiagroup.com/fonts/poppins/Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("https://cdn.fundingasiagroup.com/fonts/poppins/Poppins-MediumItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://cdn.fundingasiagroup.com/fonts/poppins/Poppins-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("https://cdn.fundingasiagroup.com/fonts/poppins/Poppins-SemiBoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Prompt";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://cdn.fundingasiagroup.com/fonts/Prompt-Regular.woff2") format("woff2"), url("https://cdn.fundingasiagroup.com/fonts/Prompt-Regular.woff") format("woff");
}
@font-face {
  font-family: "Prompt";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("https://cdn.fundingasiagroup.com/fonts/Prompt-RegularItalic.woff2") format("woff2"), url("https://cdn.fundingasiagroup.com/fonts/Prompt-RegularItalic.woff") format("woff");
}
@font-face {
  font-family: "Prompt";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://cdn.fundingasiagroup.com/fonts/Prompt-Medium.woff2") format("woff2"), url("https://cdn.fundingasiagroup.com/fonts/Prompt-Medium.woff") format("woff");
}
@font-face {
  font-family: "Prompt";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("https://cdn.fundingasiagroup.com/fonts/Prompt-MediumItalic.woff2") format("woff2"), url("https://cdn.fundingasiagroup.com/fonts/Prompt-MediumItalic.woff") format("woff");
}
@font-face {
  font-family: "Prompt";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://cdn.fundingasiagroup.com/fonts/Prompt-SemiBold.woff2") format("woff2"), url("https://cdn.fundingasiagroup.com/fonts/Prompt-SemiBold.woff") format("woff");
}
@font-face {
  font-family: "Prompt";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("https://cdn.fundingasiagroup.com/fonts/Prompt-SemiBoldItalic.woff2") format("woff2"), url("https://cdn.fundingasiagroup.com/fonts/Prompt-SemiBoldItalic.woff") format("woff");
}
@font-face {
  font-family: "Prompt";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://cdn.fundingasiagroup.com/fonts/Prompt-Bold.woff2") format("woff2"), url("https://cdn.fundingasiagroup.com/fonts/Prompt-Bold.woff") format("woff");
}
@font-face {
  font-family: "Prompt";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("https://cdn.fundingasiagroup.com/fonts/Prompt-BoldItalic.woff2") format("woff2"), url("https://cdn.fundingasiagroup.com/fonts/Prompt-BoldItalic.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://cdn.fundingasiagroup.com/fonts/Inter-Regular.woff2") format("woff2"), url("https://cdn.fundingasiagroup.com/fonts/Inter-Regular.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("https://cdn.fundingasiagroup.com/fonts/Inter-RegularItalic.woff2") format("woff2"), url("https://cdn.fundingasiagroup.com/fonts/Inter-RegularItalic.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://cdn.fundingasiagroup.com/fonts/Inter-Medium.woff2") format("woff2"), url("https://cdn.fundingasiagroup.com/fonts/Inter-Medium.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("https://cdn.fundingasiagroup.com/fonts/Inter-MediumItalic.woff2") format("woff2"), url("https://cdn.fundingasiagroup.com/fonts/Inter-MediumItalic.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://cdn.fundingasiagroup.com/fonts/Inter-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("https://cdn.fundingasiagroup.com/fonts/Inter-SemiBoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://cdn.fundingasiagroup.com/fonts/Inter-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("https://cdn.fundingasiagroup.com/fonts/Inter-BoldItalic.ttf") format("truetype");
}
.pantry a {
  text-decoration: none;
  color: #2c50ff;
}
.pantry a:visited {
  color: #2c50ff;
}
.pantry a:focus, .pantry a:hover {
  text-decoration: underline;
}
.pantry ol,
.pantry ul {
  margin: 0;
  padding-left: 2em;
}
.pantry html {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "San Francisco", Roboto, "Segoe UI", "Helvetica Neue", sans-serif;
  font-size: 62.5%;
  text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.pantry :lang(vi) {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "San Francisco", Roboto, "Segoe UI", "Helvetica Neue", sans-serif;
}
.pantry :lang(th) {
  font-family: "Inter", "Prompt", -apple-system, BlinkMacSystemFont, "San Francisco", Roboto, "Segoe UI", "Helvetica Neue", sans-serif;
}
.pantry body {
  font-family: inherit;
  color: #333;
  font-size: 16px;
  line-height: 1.5;
}
.pantry h1,
.pantry h2,
.pantry h3,
.pantry h4,
.pantry h5,
.pantry h6,
.pantry .h1,
.pantry .h2,
.pantry .h3,
.pantry .h4,
.pantry .h5,
.pantry .h6 {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "San Francisco", Roboto, "Segoe UI", "Helvetica Neue", sans-serif;
  margin: 0;
  padding: 0;
}
.pantry h1:lang(vi),
.pantry h2:lang(vi),
.pantry h3:lang(vi),
.pantry h4:lang(vi),
.pantry h5:lang(vi),
.pantry h6:lang(vi),
.pantry .h1:lang(vi),
.pantry .h2:lang(vi),
.pantry .h3:lang(vi),
.pantry .h4:lang(vi),
.pantry .h5:lang(vi),
.pantry .h6:lang(vi) {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "San Francisco", Roboto, "Segoe UI", "Helvetica Neue", sans-serif;
}
.pantry h1:lang(th),
.pantry h2:lang(th),
.pantry h3:lang(th),
.pantry h4:lang(th),
.pantry h5:lang(th),
.pantry h6:lang(th),
.pantry .h1:lang(th),
.pantry .h2:lang(th),
.pantry .h3:lang(th),
.pantry .h4:lang(th),
.pantry .h5:lang(th),
.pantry .h6:lang(th) {
  font-family: "Poppins", "Prompt", -apple-system, BlinkMacSystemFont, "San Francisco", Roboto, "Segoe UI", "Helvetica Neue", sans-serif;
}
.pantry h1,
.pantry .h1 {
  font-size: 32px;
  line-height: 1.25;
  font-weight: 700;
}
@media (min-width: 544px) {
  .pantry h1,
  .pantry .h1 {
    font-size: 40px;
  }
}
@media (min-width: 768px) {
  .pantry h1,
  .pantry .h1 {
    font-size: 60px;
    line-height: 1.1;
  }
}
.pantry h2,
.pantry .h2 {
  font-size: 28px;
  line-height: 1.4;
  font-weight: 700;
}
@media (min-width: 544px) {
  .pantry h2,
  .pantry .h2 {
    font-size: 32px;
    line-height: 1.25;
  }
}
@media (min-width: 768px) {
  .pantry h2,
  .pantry .h2 {
    font-size: 40px;
    line-height: 1.25;
  }
}
.pantry h3,
.pantry .h3 {
  font-size: 24px;
  line-height: 1.4;
  font-weight: 600;
}
@media (min-width: 544px) {
  .pantry h3,
  .pantry .h3 {
    font-size: 28px;
  }
}
@media (min-width: 768px) {
  .pantry h3,
  .pantry .h3 {
    font-size: 32px;
    line-height: 1.25;
  }
}
.pantry h4,
.pantry .h4 {
  font-size: 20px;
  line-height: 1.4;
  font-weight: 600;
}
@media (min-width: 544px) {
  .pantry h4,
  .pantry .h4 {
    font-size: 24px;
  }
}
@media (min-width: 768px) {
  .pantry h4,
  .pantry .h4 {
    font-size: 28px;
  }
}
.pantry h5,
.pantry .h5 {
  font-size: 20px;
  line-height: 1.4;
  font-weight: 600;
}
@media (min-width: 544px) {
  .pantry h5,
  .pantry .h5 {
    font-size: 24px;
  }
}
@media (min-width: 768px) {
  .pantry h5,
  .pantry .h5 {
    font-size: 24px;
  }
}
.pantry h6,
.pantry .h6 {
  font-size: 20px;
  line-height: 1.4;
  font-weight: 600;
}
@media (min-width: 544px) {
  .pantry h6,
  .pantry .h6 {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  .pantry h6,
  .pantry .h6 {
    font-size: 20px;
  }
}
.pantry small {
  font-size: 14px;
  line-height: 1.5;
}
.pantry caption {
  display: block;
  text-align: left;
  font-size: 12px;
  line-height: 1.5;
}
.pantry .accordion {
  padding: 8px;
}
.pantry .accordion[open] .pantry .accordion__summary {
  margin-bottom: 8px;
}
.pantry .accordion[open] .pantry .accordion__summary::after {
  transform: translateY(12px) rotate(225deg);
}
.pantry .accordion__summary {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  color: #2c50ff;
  font-weight: 500;
}
.pantry .accordion__summary-text {
  margin: initial;
  max-width: calc(100% - 15px);
}
.pantry .accordion__summary::after {
  content: "";
  width: 8px;
  height: 8px;
  border-width: 2px;
  border-style: solid;
  border-color: transparent #2c50ff #2c50ff transparent;
  transform: translateY(4px) rotate(45deg);
}
.pantry .accordion__summary::-webkit-details-marker, .pantry .accordion__summary::marker {
  display: none;
  content: "";
}
.pantry .accordion__details {
  color: #757575;
}
.pantry button,
.pantry input[type=submit],
.pantry input[type=button],
.pantry input[type=reset],
.pantry .btn {
  background-color: #ffffff;
  border-color: #cccccc;
  color: #222222;
  position: relative;
  display: inline-block;
  padding: 8px 12px;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "San Francisco", Roboto, "Segoe UI", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
  line-height: 1.5;
  white-space: nowrap;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  user-select: none;
  outline: none;
  text-decoration: none;
  appearance: none;
  transform: translateY(0);
  will-change: transform, box-shadow;
  transition: box-shadow 200ms cubic-bezier(0, 0, 0.2, 1), transform 200ms cubic-bezier(0, 0, 0.2, 1), background-color 200ms cubic-bezier(0, 0, 0.2, 1), color 200ms cubic-bezier(0, 0, 0.2, 1);
}
.pantry button:visited,
.pantry input[type=submit]:visited,
.pantry input[type=button]:visited,
.pantry input[type=reset]:visited,
.pantry .btn:visited {
  color: #222222;
}
.pantry button:hover, .pantry button.is-hovered,
.pantry input[type=submit]:hover,
.pantry input[type=submit].is-hovered,
.pantry input[type=button]:hover,
.pantry input[type=button].is-hovered,
.pantry input[type=reset]:hover,
.pantry input[type=reset].is-hovered,
.pantry .btn:hover,
.pantry .btn.is-hovered {
  background-color: #ffffff;
  border-color: #cccccc;
  color: #222222;
}
.pantry button:active, .pantry button.is-active,
.pantry input[type=submit]:active,
.pantry input[type=submit].is-active,
.pantry input[type=button]:active,
.pantry input[type=button].is-active,
.pantry input[type=reset]:active,
.pantry input[type=reset].is-active,
.pantry .btn:active,
.pantry .btn.is-active {
  background-color: #ffffff;
  border-color: #cccccc;
  color: #222222;
}
.pantry button:disabled, .pantry button.is-disabled,
.pantry input[type=submit]:disabled,
.pantry input[type=submit].is-disabled,
.pantry input[type=button]:disabled,
.pantry input[type=button].is-disabled,
.pantry input[type=reset]:disabled,
.pantry input[type=reset].is-disabled,
.pantry .btn:disabled,
.pantry .btn.is-disabled {
  background-color: #ffffff;
  border-color: #cccccc;
  color: #222222;
}
.pantry button.btn--flat, .pantry button.btn--flat:hover,
.pantry input[type=submit].btn--flat,
.pantry input[type=submit].btn--flat:hover,
.pantry input[type=button].btn--flat,
.pantry input[type=button].btn--flat:hover,
.pantry input[type=reset].btn--flat,
.pantry input[type=reset].btn--flat:hover,
.pantry .btn.btn--flat,
.pantry .btn.btn--flat:hover {
  border: 0;
  box-shadow: none;
}
.pantry button:hover, .pantry button.is-hovered,
.pantry input[type=submit]:hover,
.pantry input[type=submit].is-hovered,
.pantry input[type=button]:hover,
.pantry input[type=button].is-hovered,
.pantry input[type=reset]:hover,
.pantry input[type=reset].is-hovered,
.pantry .btn:hover,
.pantry .btn.is-hovered {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-decoration: none;
}
.pantry button:active, .pantry button.is-active,
.pantry input[type=submit]:active,
.pantry input[type=submit].is-active,
.pantry input[type=button]:active,
.pantry input[type=button].is-active,
.pantry input[type=reset]:active,
.pantry input[type=reset].is-active,
.pantry .btn:active,
.pantry .btn.is-active {
  transform: none;
  transition: none;
  box-shadow: none;
}
.pantry button:disabled, .pantry button.is-disabled,
.pantry input[type=submit]:disabled,
.pantry input[type=submit].is-disabled,
.pantry input[type=button]:disabled,
.pantry input[type=button].is-disabled,
.pantry input[type=reset]:disabled,
.pantry input[type=reset].is-disabled,
.pantry .btn:disabled,
.pantry .btn.is-disabled {
  box-shadow: none;
  opacity: 0.6;
  transform: none;
  cursor: not-allowed;
  user-select: initial;
}
.pantry button:disabled:hover, .pantry button.is-disabled:hover,
.pantry input[type=submit]:disabled:hover,
.pantry input[type=submit].is-disabled:hover,
.pantry input[type=button]:disabled:hover,
.pantry input[type=button].is-disabled:hover,
.pantry input[type=reset]:disabled:hover,
.pantry input[type=reset].is-disabled:hover,
.pantry .btn:disabled:hover,
.pantry .btn.is-disabled:hover {
  text-decoration: none;
}
.pantry button + .pantry button,
.pantry button + .pantry input[type=submit],
.pantry button + .pantry input[type=button],
.pantry button + .pantry input[type=reset],
.pantry button + .pantry .btn,
.pantry input[type=submit] + .pantry button,
.pantry input[type=submit] + .pantry input[type=submit],
.pantry input[type=submit] + .pantry input[type=button],
.pantry input[type=submit] + .pantry input[type=reset],
.pantry input[type=submit] + .pantry .btn,
.pantry input[type=button] + .pantry button,
.pantry input[type=button] + .pantry input[type=submit],
.pantry input[type=button] + .pantry input[type=button],
.pantry input[type=button] + .pantry input[type=reset],
.pantry input[type=button] + .pantry .btn,
.pantry input[type=reset] + .pantry button,
.pantry input[type=reset] + .pantry input[type=submit],
.pantry input[type=reset] + .pantry input[type=button],
.pantry input[type=reset] + .pantry input[type=reset],
.pantry input[type=reset] + .pantry .btn,
.pantry .btn + .pantry button,
.pantry .btn + .pantry input[type=submit],
.pantry .btn + .pantry input[type=button],
.pantry .btn + .pantry input[type=reset],
.pantry .btn + .pantry .btn {
  margin-left: 8px;
}
.pantry button:lang(vi),
.pantry input[type=submit]:lang(vi),
.pantry input[type=button]:lang(vi),
.pantry input[type=reset]:lang(vi),
.pantry .btn:lang(vi) {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "San Francisco", Roboto, "Segoe UI", "Helvetica Neue", sans-serif;
}
.pantry button:lang(th),
.pantry input[type=submit]:lang(th),
.pantry input[type=button]:lang(th),
.pantry input[type=reset]:lang(th),
.pantry .btn:lang(th) {
  font-family: "Poppins", "Prompt", -apple-system, BlinkMacSystemFont, "San Francisco", Roboto, "Segoe UI", "Helvetica Neue", sans-serif;
}
.pantry .btn--primary {
  background-color: #2c50ff;
  border-color: #2c50ff;
  color: #ffffff;
}
.pantry .btn--primary:visited {
  color: #ffffff;
}
.pantry .btn--primary:hover, .pantry .btn--primary.is-hovered {
  background-color: #2c50ff;
  border-color: #2c50ff;
  color: #ffffff;
}
.pantry .btn--primary:active, .pantry .btn--primary.is-active {
  background-color: #0b2e8f;
  border-color: #0b2e8f;
  color: #ffffff;
}
.pantry .btn--primary:disabled, .pantry .btn--primary.is-disabled {
  background-color: #2c50ff;
  border-color: #2c50ff;
  color: #ffffff;
}
.pantry .btn--secondary {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #2c50ff;
}
.pantry .btn--secondary:visited {
  color: #2c50ff;
}
.pantry .btn--secondary:hover, .pantry .btn--secondary.is-hovered {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #2c50ff;
}
.pantry .btn--secondary:active, .pantry .btn--secondary.is-active {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #0b2e8f;
}
.pantry .btn--secondary:disabled, .pantry .btn--secondary.is-disabled {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #2c50ff;
}
.pantry .btn--large {
  padding: 12px 16px;
}
.pantry .btn--small {
  font-size: 14px;
  padding: 6px 12px;
}
.pantry .btn--block {
  display: block;
  text-align: center;
  width: 100%;
}
.pantry .btn--block + .btn--block {
  margin: 8px 0 0;
}
.pantry .btn--link {
  background-color: transparent;
  border-color: transparent;
  color: #2c50ff;
  box-shadow: none;
}
.pantry .btn--link:visited {
  color: #2c50ff;
}
.pantry .btn--link:hover, .pantry .btn--link.is-hovered {
  background-color: transparent;
  border-color: transparent;
  color: rgba(44, 80, 255, 0.8);
}
.pantry .btn--link:active, .pantry .btn--link.is-active {
  background-color: transparent;
  border-color: transparent;
  color: #0b2e8f;
}
.pantry .btn--link:disabled, .pantry .btn--link.is-disabled {
  background-color: transparent;
  border-color: transparent;
  color: #2c50ff;
}
.pantry .btn--link:hover, .pantry .btn--link.is-hovered {
  box-shadow: none;
  transform: none;
}
.pantry .btn--table-action {
  background-color: transparent;
  border-color: transparent;
  color: inherit;
  line-height: 1;
  padding: 4px;
  box-shadow: none;
}
.pantry .btn--table-action:visited {
  color: inherit;
}
.pantry .btn--table-action:hover, .pantry .btn--table-action.is-hovered {
  background-color: transparent;
  border-color: transparent;
  color: #2c50ff;
}
.pantry .btn--table-action:active, .pantry .btn--table-action.is-active {
  background-color: transparent;
  border-color: transparent;
  color: #0b2e8f;
}
.pantry .btn--table-action:disabled, .pantry .btn--table-action.is-disabled {
  background-color: transparent;
  border-color: transparent;
  color: inherit;
}
.pantry .btn--table-action:hover, .pantry .btn--table-action.is-hovered {
  box-shadow: none;
  transform: none;
}
.pantry .btn .icon-circled {
  vertical-align: baseline;
}
.pantry .card {
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.pantry .card__content,
.pantry .card__actions {
  padding: 24px;
}
.pantry .card__media {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  height: 0;
  padding-top: 40.5%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.pantry .card__actions {
  padding-top: 0;
  display: flex;
  flex-wrap: wrap;
}
.pantry .card__actions .btn {
  width: 100%;
  text-align: center;
}
@media (min-width: 1004px) {
  .pantry .card__actions .btn {
    flex: 1 1 0;
  }
}
.pantry .card__actions .btn + .btn {
  margin-top: 16px;
  margin-left: 0;
}
@media (min-width: 1004px) {
  .pantry .card__actions .btn + .btn {
    margin-left: 16px;
    margin-top: 0;
  }
}
.pantry .card--equal-height {
  min-height: 100%;
}
.pantry .card--equal-height .card__content {
  flex: 1 0 auto;
}
.pantry .card--no-shadow {
  box-shadow: none;
}
.pantry .card--compact > .card__content,
.pantry .card--compact > .card__actions {
  padding: 12px;
}
.pantry .card--compact > .card__actions {
  padding-top: 0;
}
.pantry .card--comfortable > .card__content,
.pantry .card--comfortable > .card__actions {
  padding: 48px;
}
.pantry .card--comfortable > .card__actions {
  padding-top: 0;
}
.pantry .card--info {
  background-color: #f3f5fd;
}
.pantry .card--mono {
  background-color: #f1f1f2;
}
.pantry input[type=checkbox],
.pantry input[type=radio] {
  position: absolute;
  width: 1.25em;
  height: 1.25em;
  margin: 0;
  opacity: 0;
}
.pantry .form-choice {
  font-size: 16px;
}
.pantry .form-choice + .form-choice {
  margin-top: 16px;
}
.pantry .form-choice__label {
  position: relative;
  display: flex;
  font-size: 1em;
  line-height: 1.25;
}
.pantry .form-choice__label::before {
  content: " ";
  flex: 0 0 auto;
  display: inline-block;
  margin-right: 8px;
  width: 1.25em;
  height: 1.25em;
  border: 2px solid #8f8f8f;
  cursor: pointer;
  will-change: border-color, background-color, box-shadow;
  transition: border-color 100ms cubic-bezier(0, 0, 0.2, 1), background-color 100ms cubic-bezier(0, 0, 0.2, 1), box-shadow 100ms cubic-bezier(0, 0, 0.2, 1);
}
.pantry .form-choice__label::after {
  content: " ";
  position: absolute;
  opacity: 0;
  will-change: transform, opacity;
  transition: opacity 100ms cubic-bezier(0, 0, 0.2, 1), transform 100ms cubic-bezier(0, 0, 0.2, 1);
}
.pantry input[type=checkbox]:focus + .form-choice__label::before,
.pantry input[type=checkbox]:active + .form-choice__label::before,
.pantry input[type=radio]:focus + .form-choice__label::before,
.pantry input[type=radio]:active + .form-choice__label::before {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.pantry input[type=checkbox]:disabled + .form-choice__label,
.pantry input[type=radio]:disabled + .form-choice__label {
  pointer-events: none;
}
.pantry .form-choice--inline {
  display: inline-block;
}
.pantry .form-choice--inline + .pantry .form-choice--inline {
  margin-top: 0;
  margin-left: 16px;
}
.pantry .form-choice__label--checkbox::before {
  border-radius: 2px;
  background-color: #ffffff;
}
.pantry .form-choice__label--checkbox::after {
  height: 0.4em;
  width: 0.8em;
  left: 0.25em;
  top: 0.3em;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  transform: rotate(-45deg) scale(0);
}
.pantry input[type=checkbox]:checked + .form-choice__label--checkbox::before {
  border-color: #3e7eff;
  background-color: #3e7eff;
}
.pantry input[type=checkbox]:checked + .form-choice__label--checkbox::after {
  opacity: 1;
  transform: rotate(-45deg) scale(1);
}
.pantry input[type=checkbox]:disabled ~ .form-choice__label--checkbox::before {
  border-color: #cccccc;
  background-color: #f1f1f2;
}
.pantry input[type=checkbox]:disabled:checked ~ .form-choice__label--checkbox::before {
  background-color: #cccccc;
}
.pantry .form-choice__label--radio::before, .pantry .form-choice__label--radio::after {
  width: 1.25em;
  height: 1.25em;
  border-radius: 50%;
}
.pantry .form-choice__label--radio::before {
  z-index: 2;
  background-color: #ffffff;
}
.pantry .form-choice__label--radio::after {
  content: " ";
  background: #3e7eff;
  border: 0.3125em solid #ffffff;
  transform: scale(0);
}
.pantry input[type=radio]:checked + .form-choice__label--radio::before {
  border-color: #3e7eff;
  background-color: transparent;
}
.pantry input[type=radio]:checked + .form-choice__label--radio::after {
  opacity: 1;
  transform: scale(1);
}
.pantry input[type=radio]:disabled + .form-choice__label--radio::before {
  background-color: #f1f1f2;
  border-color: #cccccc;
}
.pantry input[type=radio]:disabled:checked + .form-choice__label--radio::before {
  background-color: transparent;
}
.pantry input[type=radio]:disabled:checked + .form-choice__label--radio::after {
  background-color: #cccccc;
  border-color: #f1f1f2;
}
.pantry .form-control {
  display: inline-block;
  width: 100%;
  padding-bottom: 23px;
  color: #222222;
  line-height: 1.5;
}
.pantry .form-control.has-help, .pantry .form-control.has-error {
  height: 0;
}
.pantry .form-control.has-error .form-input,
.pantry .form-control.has-error .form-textarea,
.pantry .form-control.has-error .input-group,
.pantry .form-control.has-error select {
  border-color: #ff2945;
  box-shadow: none;
}
.pantry .form-control.has-error .form-choice__label::before {
  border-color: #ff2945;
}
.pantry .form-control + .pantry .form-control {
  margin-top: 4px;
}
.pantry .form-label {
  display: block;
  width: 100%;
  margin-bottom: 8px;
  color: #222222;
  font-size: 14px;
  font-weight: 500;
}
.pantry .form-control__help,
.pantry .form-control__error {
  display: block;
  font-size: 12px;
  padding-top: 4px;
}
.pantry .form-control__help {
  color: #8f8f8f;
}
.pantry .form-control__error {
  color: #cc1830;
}
.pantry .file-selector-wrapper--inline {
  display: flex;
  align-items: center;
}
.pantry .file-selector-wrapper--inline .file-selector + .file-list {
  margin-top: 0;
  margin-left: 8px;
}
.pantry .file-selector {
  position: relative;
  display: inline-block;
}
.pantry .file-selector .btn .icon + span {
  margin-left: 8px;
}
.pantry .file-selector input[type=file] {
  position: absolute;
  opacity: 0;
  overflow: hidden;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.pantry .file-selector + .file-list {
  margin-top: 8px;
}
.pantry .file-list {
  padding: 0;
  margin: 0;
  flex: 1 1;
  list-style: none;
}
.pantry .file-list__file + .file-list__file {
  margin-top: 8px;
}
.pantry .file-list__container {
  background-color: #f3f5fd;
  color: #4a4a4a;
  border-radius: 2px;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 8px;
}
.pantry .file-list__description {
  line-height: 1.71429;
  flex: 1 0;
}
.pantry .file-list__icon {
  font-size: 1.14em;
  line-height: 1;
  color: #cccccc;
  margin-right: 8px;
  text-align: center;
}
.pantry .file-list__action {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "San Francisco", Roboto, "Segoe UI", "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-size: 0.85714em;
  border: 0;
  padding: 0;
  box-shadow: none;
  background: transparent;
}
.pantry .file-list__action:hover {
  box-shadow: none;
  transform: none;
  background: transparent;
}
.pantry .file-list__action:lang(vi) {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "San Francisco", Roboto, "Segoe UI", "Helvetica Neue", sans-serif;
}
.pantry .file-list__action:lang(th) {
  font-family: "Inter", "Prompt", -apple-system, BlinkMacSystemFont, "San Francisco", Roboto, "Segoe UI", "Helvetica Neue", sans-serif;
}
.pantry .file-list__error {
  font-size: 12px;
  color: #cc1830;
  margin-top: 4px;
}
.pantry .file-list__file.is-uploading .file-list__action {
  color: #2c50ff;
}
.pantry .file-list__file.is-success .file-list__icon {
  color: #08c0a6;
}
.pantry .file-list__file.is-success .file-list__action {
  color: #4a4a4a !important;
}
.pantry .file-list__file.is-error .file-list__container {
  background-color: #f5d7d7;
}
.pantry .file-list__file.is-error .file-list__icon {
  color: #cc1830;
}
.pantry .file-list__file.is-error .file-list__action {
  color: #4a4a4a !important;
}
.pantry .icon {
  position: relative;
  top: 0.125em;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentColor;
}
.pantry .icon-circled {
  display: inline-block;
  position: relative;
  line-height: 0;
  padding: 0.3em;
  border-radius: 50%;
  vertical-align: middle;
}
.pantry .icon-circled .icon {
  width: 0.65em;
  height: 0.65em;
  top: 0;
}
.pantry .icon-circled + * {
  vertical-align: middle;
}
.pantry .form-input {
  display: block;
  width: 100%;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 2px;
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.12);
  padding: 8px 16px;
  color: #222222;
  font-family: inherit;
  font-size: 16px;
  line-height: 1.5;
  will-change: border-color, box-shadow;
  transition: border-color 100ms cubic-bezier(0, 0, 0.2, 1), box-shadow 100ms cubic-bezier(0, 0, 0.2, 1);
}
.pantry .form-input:disabled, .pantry .form-input:read-only {
  background: #f1f1f2;
  color: #757575;
  box-shadow: none;
}
.pantry .form-input:disabled {
  cursor: not-allowed;
}
.pantry .form-input:focus {
  outline: 0;
  border-color: #79a6ff;
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.12), 0 0 0 1px #79a6ff;
}
.pantry .input-group {
  display: flex;
  align-items: center;
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.12);
  border: 1px solid #cccccc;
  background-color: #ffffff;
  border-radius: 2px;
  will-change: border-color, box-shadow;
  transition: border-color 100ms cubic-bezier(0, 0, 0.2, 1), box-shadow 100ms cubic-bezier(0, 0, 0.2, 1);
}
.pantry .input-group .form-input {
  box-shadow: none;
  border: 0;
  border-radius: 0;
  background: none;
}
.pantry .input-group .form-input:first-child {
  padding-right: 4px;
}
.pantry .input-group .form-input:not(:first-child):not(:last-child) {
  padding-right: 4px;
  padding-left: 4px;
}
.pantry .input-group .form-input:last-child {
  padding-left: 4px;
}
.pantry .input-group.is-disabled, .pantry .input-group.is-readonly {
  background: #f1f1f2;
  color: #757575;
  box-shadow: none;
}
.pantry .input-group.is-disabled {
  cursor: not-allowed;
}
.pantry .input-group:focus-within {
  outline: 0;
  border-color: #79a6ff;
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.12), 0 0 0 1px #79a6ff;
}
.pantry .input-group__prefix,
.pantry .input-group__suffix {
  flex: 0 0 auto;
  padding: 8px 0;
}
.pantry .input-group__prefix {
  padding-left: 16px;
}
.pantry .input-group__suffix {
  padding-right: 16px;
}
.pantry .container {
  display: block;
  margin: 0 auto;
  padding: 0 12px;
  width: 100%;
}
@media (min-width: 544px) {
  .pantry .container {
    max-width: 568px;
  }
}
@media (min-width: 768px) {
  .pantry .container {
    max-width: 792px;
  }
}
@media (min-width: 1004px) {
  .pantry .container {
    max-width: 1028px;
  }
}
.pantry .container--fluid {
  max-width: 100%;
}
.pantry .row {
  display: flex;
  flex-wrap: wrap;
  margin: -12px;
}
.pantry .row + .pantry .row {
  margin-top: 12px;
}
.pantry .row + .row-no-grid {
  margin-top: 24px;
}
.pantry .row::after {
  content: " ";
  display: table;
  clear: both;
}
.pantry .row-no-grid + .pantry .row-no-grid {
  margin-top: 24px;
}
.pantry .row-no-grid + .row {
  margin-top: 12px;
}
.pantry .col {
  padding: 0 12px;
  margin: 12px 0;
  width: 100%;
  max-width: 100%;
  float: left;
}
.pantry .col--1\/12 {
  width: 8.3333333333%;
  flex: none;
}
.pantry .col--2\/12 {
  width: 16.6666666667%;
  flex: none;
}
.pantry .col--3\/12 {
  width: 25%;
  flex: none;
}
.pantry .col--4\/12 {
  width: 33.3333333333%;
  flex: none;
}
.pantry .col--5\/12 {
  width: 41.6666666667%;
  flex: none;
}
.pantry .col--6\/12 {
  width: 50%;
  flex: none;
}
.pantry .col--7\/12 {
  width: 58.3333333333%;
  flex: none;
}
.pantry .col--8\/12 {
  width: 66.6666666667%;
  flex: none;
}
.pantry .col--9\/12 {
  width: 75%;
  flex: none;
}
.pantry .col--10\/12 {
  width: 83.3333333333%;
  flex: none;
}
.pantry .col--11\/12 {
  width: 91.6666666667%;
  flex: none;
}
.pantry .col--12\/12 {
  width: 100%;
  flex: none;
}
.pantry .col--auto {
  width: auto;
  flex: none;
}
.pantry .col--fill {
  width: auto;
  flex: 1 1 0;
}
@media (min-width: 544px) {
  .pantry .col--1\/12-small {
    width: 8.3333333333%;
    flex: none;
  }
}
@media (min-width: 544px) {
  .pantry .col--2\/12-small {
    width: 16.6666666667%;
    flex: none;
  }
}
@media (min-width: 544px) {
  .pantry .col--3\/12-small {
    width: 25%;
    flex: none;
  }
}
@media (min-width: 544px) {
  .pantry .col--4\/12-small {
    width: 33.3333333333%;
    flex: none;
  }
}
@media (min-width: 544px) {
  .pantry .col--5\/12-small {
    width: 41.6666666667%;
    flex: none;
  }
}
@media (min-width: 544px) {
  .pantry .col--6\/12-small {
    width: 50%;
    flex: none;
  }
}
@media (min-width: 544px) {
  .pantry .col--7\/12-small {
    width: 58.3333333333%;
    flex: none;
  }
}
@media (min-width: 544px) {
  .pantry .col--8\/12-small {
    width: 66.6666666667%;
    flex: none;
  }
}
@media (min-width: 544px) {
  .pantry .col--9\/12-small {
    width: 75%;
    flex: none;
  }
}
@media (min-width: 544px) {
  .pantry .col--10\/12-small {
    width: 83.3333333333%;
    flex: none;
  }
}
@media (min-width: 544px) {
  .pantry .col--11\/12-small {
    width: 91.6666666667%;
    flex: none;
  }
}
@media (min-width: 544px) {
  .pantry .col--12\/12-small {
    width: 100%;
    flex: none;
  }
}
@media (min-width: 544px) {
  .pantry .col--auto-small {
    width: auto;
    flex: none;
  }
}
@media (min-width: 544px) {
  .pantry .col--fill-small {
    width: auto;
    flex: 1 1 0;
  }
}
@media (min-width: 768px) {
  .pantry .col--1\/12-medium {
    width: 8.3333333333%;
    flex: none;
  }
}
@media (min-width: 768px) {
  .pantry .col--2\/12-medium {
    width: 16.6666666667%;
    flex: none;
  }
}
@media (min-width: 768px) {
  .pantry .col--3\/12-medium {
    width: 25%;
    flex: none;
  }
}
@media (min-width: 768px) {
  .pantry .col--4\/12-medium {
    width: 33.3333333333%;
    flex: none;
  }
}
@media (min-width: 768px) {
  .pantry .col--5\/12-medium {
    width: 41.6666666667%;
    flex: none;
  }
}
@media (min-width: 768px) {
  .pantry .col--6\/12-medium {
    width: 50%;
    flex: none;
  }
}
@media (min-width: 768px) {
  .pantry .col--7\/12-medium {
    width: 58.3333333333%;
    flex: none;
  }
}
@media (min-width: 768px) {
  .pantry .col--8\/12-medium {
    width: 66.6666666667%;
    flex: none;
  }
}
@media (min-width: 768px) {
  .pantry .col--9\/12-medium {
    width: 75%;
    flex: none;
  }
}
@media (min-width: 768px) {
  .pantry .col--10\/12-medium {
    width: 83.3333333333%;
    flex: none;
  }
}
@media (min-width: 768px) {
  .pantry .col--11\/12-medium {
    width: 91.6666666667%;
    flex: none;
  }
}
@media (min-width: 768px) {
  .pantry .col--12\/12-medium {
    width: 100%;
    flex: none;
  }
}
@media (min-width: 768px) {
  .pantry .col--auto-medium {
    width: auto;
    flex: none;
  }
}
@media (min-width: 768px) {
  .pantry .col--fill-medium {
    width: auto;
    flex: 1 1 0;
  }
}
@media (min-width: 1004px) {
  .pantry .col--1\/12-large {
    width: 8.3333333333%;
    flex: none;
  }
}
@media (min-width: 1004px) {
  .pantry .col--2\/12-large {
    width: 16.6666666667%;
    flex: none;
  }
}
@media (min-width: 1004px) {
  .pantry .col--3\/12-large {
    width: 25%;
    flex: none;
  }
}
@media (min-width: 1004px) {
  .pantry .col--4\/12-large {
    width: 33.3333333333%;
    flex: none;
  }
}
@media (min-width: 1004px) {
  .pantry .col--5\/12-large {
    width: 41.6666666667%;
    flex: none;
  }
}
@media (min-width: 1004px) {
  .pantry .col--6\/12-large {
    width: 50%;
    flex: none;
  }
}
@media (min-width: 1004px) {
  .pantry .col--7\/12-large {
    width: 58.3333333333%;
    flex: none;
  }
}
@media (min-width: 1004px) {
  .pantry .col--8\/12-large {
    width: 66.6666666667%;
    flex: none;
  }
}
@media (min-width: 1004px) {
  .pantry .col--9\/12-large {
    width: 75%;
    flex: none;
  }
}
@media (min-width: 1004px) {
  .pantry .col--10\/12-large {
    width: 83.3333333333%;
    flex: none;
  }
}
@media (min-width: 1004px) {
  .pantry .col--11\/12-large {
    width: 91.6666666667%;
    flex: none;
  }
}
@media (min-width: 1004px) {
  .pantry .col--12\/12-large {
    width: 100%;
    flex: none;
  }
}
@media (min-width: 1004px) {
  .pantry .col--auto-large {
    width: auto;
    flex: none;
  }
}
@media (min-width: 1004px) {
  .pantry .col--fill-large {
    width: auto;
    flex: 1 1 0;
  }
}
.pantry .row--align-left {
  justify-content: flex-start;
}
@media (min-width: 544px) {
  .pantry .row--align-left-small {
    justify-content: flex-start;
  }
}
@media (min-width: 768px) {
  .pantry .row--align-left-medium {
    justify-content: flex-start;
  }
}
@media (min-width: 1004px) {
  .pantry .row--align-left-large {
    justify-content: flex-start;
  }
}
.pantry .row--align-right {
  justify-content: flex-end;
}
@media (min-width: 544px) {
  .pantry .row--align-right-small {
    justify-content: flex-end;
  }
}
@media (min-width: 768px) {
  .pantry .row--align-right-medium {
    justify-content: flex-end;
  }
}
@media (min-width: 1004px) {
  .pantry .row--align-right-large {
    justify-content: flex-end;
  }
}
.pantry .row--align-center {
  justify-content: center;
}
@media (min-width: 544px) {
  .pantry .row--align-center-small {
    justify-content: center;
  }
}
@media (min-width: 768px) {
  .pantry .row--align-center-medium {
    justify-content: center;
  }
}
@media (min-width: 1004px) {
  .pantry .row--align-center-large {
    justify-content: center;
  }
}
.pantry .row--align-top {
  align-items: flex-start;
}
@media (min-width: 544px) {
  .pantry .row--align-top-small {
    align-items: flex-start;
  }
}
@media (min-width: 768px) {
  .pantry .row--align-top-medium {
    align-items: flex-start;
  }
}
@media (min-width: 1004px) {
  .pantry .row--align-top-large {
    align-items: flex-start;
  }
}
.pantry .row--align-bottom {
  align-items: flex-end;
}
@media (min-width: 544px) {
  .pantry .row--align-bottom-small {
    align-items: flex-end;
  }
}
@media (min-width: 768px) {
  .pantry .row--align-bottom-medium {
    align-items: flex-end;
  }
}
@media (min-width: 1004px) {
  .pantry .row--align-bottom-large {
    align-items: flex-end;
  }
}
.pantry .row--align-middle {
  align-items: center;
}
@media (min-width: 544px) {
  .pantry .row--align-middle-small {
    align-items: center;
  }
}
@media (min-width: 768px) {
  .pantry .row--align-middle-medium {
    align-items: center;
  }
}
@media (min-width: 1004px) {
  .pantry .row--align-middle-large {
    align-items: center;
  }
}
.pantry .col--align-top {
  align-self: flex-start;
}
@media (min-width: 544px) {
  .pantry .col--align-top-small {
    align-self: flex-start;
  }
}
@media (min-width: 768px) {
  .pantry .col--align-top-medium {
    align-self: flex-start;
  }
}
@media (min-width: 1004px) {
  .pantry .col--align-top-large {
    align-self: flex-start;
  }
}
.pantry .col--align-bottom {
  align-self: flex-end;
}
@media (min-width: 544px) {
  .pantry .col--align-bottom-small {
    align-self: flex-end;
  }
}
@media (min-width: 768px) {
  .pantry .col--align-bottom-medium {
    align-self: flex-end;
  }
}
@media (min-width: 1004px) {
  .pantry .col--align-bottom-large {
    align-self: flex-end;
  }
}
.pantry .col--align-middle {
  align-self: center;
}
@media (min-width: 544px) {
  .pantry .col--align-middle-small {
    align-self: center;
  }
}
@media (min-width: 768px) {
  .pantry .col--align-middle-medium {
    align-self: center;
  }
}
@media (min-width: 1004px) {
  .pantry .col--align-middle-large {
    align-self: center;
  }
}
.pantry .col--align-left {
  margin-right: auto;
}
@media (min-width: 544px) {
  .pantry .col--align-left-small {
    margin-right: auto;
  }
}
@media (min-width: 768px) {
  .pantry .col--align-left-medium {
    margin-right: auto;
  }
}
@media (min-width: 1004px) {
  .pantry .col--align-left-large {
    margin-right: auto;
  }
}
.pantry .col--align-center {
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 544px) {
  .pantry .col--align-center-small {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 768px) {
  .pantry .col--align-center-medium {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1004px) {
  .pantry .col--align-center-large {
    margin-left: auto;
    margin-right: auto;
  }
}
.pantry .col--align-right {
  margin-left: auto;
}
@media (min-width: 544px) {
  .pantry .col--align-right-small {
    margin-left: auto;
  }
}
@media (min-width: 768px) {
  .pantry .col--align-right-medium {
    margin-left: auto;
  }
}
@media (min-width: 1004px) {
  .pantry .col--align-right-large {
    margin-left: auto;
  }
}
.pantry .logo {
  height: 4.5rem;
  width: 4.5rem;
}
.pantry .logo--fs {
  width: 27rem;
}
.pantry .logo--fsmk {
  width: 41.25rem;
}
.pantry .logo--stacked {
  width: 13.5rem;
}
.pantry .modal,
.pantry .modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.pantry .modal-container {
  display: none;
  background-color: rgba(0, 0, 0, 0.7);
  height: 100%;
  z-index: 20;
}
.pantry .modal-container.is-open {
  display: block;
}
.pantry .modal {
  padding: 0;
  max-height: 100vh;
  overflow-y: auto;
  border: 16px solid transparent;
}
@media (min-width: 544px) {
  .pantry .modal {
    max-width: 488px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.pantry .modal__content {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 16px;
  height: 100%;
  width: 100%;
}
@media (min-width: 544px) {
  .pantry .modal__content {
    padding: 24px;
  }
}
.pantry .modal__close {
  padding: 0;
  display: block;
  box-shadow: none;
  border: 0;
  line-height: 1;
  font-size: 2rem;
  color: #adadad;
  margin-bottom: 16px;
  margin-left: auto;
  width: 1em;
  cursor: pointer;
}
.pantry .modal__close:hover {
  color: #3e7eff;
  transform: none;
  box-shadow: none;
}
@media (min-width: 544px) {
  .pantry .modal__close {
    margin-bottom: 24px;
  }
}
.pantry .modal__hero {
  text-align: center;
  margin-bottom: 32px;
}
.pantry .modal__header {
  text-align: center;
  margin-bottom: 8px;
}
.pantry .modal__actions {
  margin-top: 48px;
}
.pantry .nav-submenu__item a:not(.btn), .pantry .nav-submenu__title, .pantry .nav-menu__item a:not(.btn) {
  text-decoration: none;
  outline: 0;
  display: flex;
  color: #4a4a4a;
  padding: 12px 16px;
  border-radius: 4px;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1), background-color 200ms cubic-bezier(0, 0, 0.2, 1);
}
.pantry .nav-submenu__item a:hover:not(.btn), .pantry .nav-submenu__title:hover, .pantry .nav-menu__item a:hover:not(.btn), .pantry .nav-submenu__item a:focus:not(.btn), .pantry .nav-submenu__title:focus, .pantry .nav-menu__item a:focus:not(.btn) {
  background-color: #f3f5fd;
}
.pantry .nav-submenu__item a:active:not(.btn), .pantry .nav-submenu__title:active, .pantry .nav-menu__item a:active:not(.btn), .pantry .nav-submenu__item a:focus:not(.btn), .pantry .nav-submenu__title:focus, .pantry .nav-menu__item a:focus:not(.btn) {
  color: #2c50ff;
}
.pantry .nav {
  position: sticky;
  top: 0;
  z-index: 5;
  width: 100%;
}
@media (min-width: 768px) {
  .pantry .nav {
    height: auto;
  }
}
.pantry .nav.is-open {
  height: 100vh;
}
@media (min-width: 768px) {
  .pantry .nav.is-open {
    height: auto;
  }
}
.pantry .nav.is-open .nav-menu--hamburger {
  transform: translateY(0);
}
.pantry .nav.is-open .navbar {
  box-shadow: none;
  border-bottom: 1px solid #e0e0e0;
}
@media (min-width: 544px) {
  .pantry .nav.is-open .navbar {
    border-bottom: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
}
.pantry .nav.is-open .navbar__brand {
  opacity: 0;
  width: 0;
}
@media (min-width: 768px) {
  .pantry .nav.is-open .navbar__brand {
    opacity: 1;
  }
}
.pantry .nav.is-open .navbar__hamburger {
  color: #757575;
}
.pantry .nav.is-open .navbar__hamburger-indicator {
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: rotate(45deg);
}
.pantry .nav.is-open .navbar__hamburger-indicator::before {
  top: 0;
  opacity: 0;
  transition: top 75ms ease, opacity 75ms ease 0.12s;
}
.pantry .nav.is-open .navbar__hamburger-indicator::after {
  bottom: 0;
  transition: bottom 75ms ease, transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
  transform: rotate(-90deg);
}
.pantry .nav.is-open .navbar__hamburger-return {
  width: auto;
  margin-right: auto;
}
.pantry .nav.is-open.is-submenu-open .navbar__hamburger-return {
  display: flex;
  opacity: 1;
}
@media (min-width: 768px) {
  .pantry .nav.is-open.is-submenu-open .navbar__hamburger-return {
    display: none;
  }
}
.pantry .nav.is-open.is-submenu-open .nav-menu__items {
  transform: translateX(-100%);
}
@media (min-width: 768px) {
  .pantry .nav.is-open.is-submenu-open .nav-menu__items {
    transform: none;
  }
}
.pantry .navbar {
  display: flex;
  align-items: center;
  padding: 16px 24px;
  line-height: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  min-height: 80px;
  position: relative;
  z-index: 3;
  background-color: #ffffff;
}
@media (min-width: 768px) {
  .pantry .navbar {
    padding-left: 32px;
    padding-right: 32px;
  }
}
.pantry .navbar__brand {
  display: inline-block;
  transition: opacity 200ms linear;
}
.pantry .navbar__hamburger-return {
  opacity: 0;
  width: 0;
  border: 0;
  padding: 0;
  box-shadow: none;
  display: flex;
  align-items: center;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "San Francisco", Roboto, "Segoe UI", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
  transition: opacity 200ms linear;
}
.pantry .navbar__hamburger-return::before {
  content: " ";
  display: inline-block;
  margin-right: 8px;
  height: 0.57143em;
  width: 0.57143em;
  border-style: solid;
  border-width: 2px 0 0 2px;
  border-color: #4a4a4a;
  transform: rotate(-45deg);
}
.pantry .navbar__hamburger-return:hover {
  transform: none;
  box-shadow: none;
  color: #2c50ff;
}
.pantry .navbar__hamburger-return:lang(vi) {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "San Francisco", Roboto, "Segoe UI", "Helvetica Neue", sans-serif;
}
.pantry .navbar__hamburger-return:lang(th) {
  font-family: "Inter", "Prompt", -apple-system, BlinkMacSystemFont, "San Francisco", Roboto, "Segoe UI", "Helvetica Neue", sans-serif;
}
.pantry .navbar__hamburger {
  font-size: 2rem;
  width: 1em;
  height: 1em;
  color: #4a4a4a;
  margin-left: auto;
  border: 0;
  padding: 0;
  background: none;
  box-shadow: none;
}
.pantry .navbar__hamburger:hover {
  color: #2c50ff;
  transform: none;
  box-shadow: none;
}
@media (min-width: 768px) {
  .pantry .navbar__hamburger {
    display: none;
  }
}
.pantry .navbar__hamburger-indicator {
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  background-color: currentColor;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-duration: 75ms;
}
.pantry .navbar__hamburger-indicator::before, .pantry .navbar__hamburger-indicator::after {
  content: " ";
  left: 0;
  position: absolute;
  width: 100%;
  height: 2px;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform;
  background-color: currentColor;
}
.pantry .navbar__hamburger-indicator::before {
  top: -0.3em;
  will-change: top;
  transition: top 75ms ease 0.12s, opacity 75ms ease;
}
.pantry .navbar__hamburger-indicator::after {
  bottom: -0.3em;
  will-change: bottom;
  transition: bottom 75ms ease 0.12s, transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.pantry .nav-menu {
  margin-left: auto;
  display: none;
  background-color: #ffffff;
  position: relative;
}
@media (min-width: 768px) {
  .pantry .nav-menu {
    display: block;
  }
}
.pantry .nav-menu__items {
  height: 100%;
  list-style: none;
  padding: 16px 8px;
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.71429;
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1);
}
@media (min-width: 768px) {
  .pantry .nav-menu__items {
    height: auto;
    display: flex;
    align-items: center;
    padding: 0;
  }
}
.pantry .nav-menu--hamburger {
  display: block;
  position: absolute;
  padding-top: 80px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  transform: translateY(-100%);
  transition: 300ms transform cubic-bezier(0.215, 0.61, 0.355, 1);
}
@media (min-width: 768px) {
  .pantry .nav-menu--hamburger {
    display: none;
  }
}
.pantry .nav-menu--hamburger .nav-menu__item .btn {
  text-align: center;
  width: 100%;
}
.pantry .nav-menu__item--separator {
  padding: 0;
  border-width: 0;
  border-color: #e0e0e0;
  border-style: solid;
  border-bottom-width: 1px;
  height: auto;
  width: 100vw;
  margin: 12px -8px;
  pointer-events: none;
}
@media (min-width: 768px) {
  .pantry .nav-menu__item--separator {
    height: 3.42857em;
    width: auto;
    margin: 0 4px;
    border-right-width: 1px;
  }
}
.pantry .nav-menu__item + .nav-menu__item,
.pantry .nav-menu__item + .nav-submenu {
  margin-top: 4px;
}
@media (min-width: 768px) {
  .pantry .nav-menu__item + .nav-menu__item,
  .pantry .nav-menu__item + .nav-submenu {
    margin-top: 0;
    margin-left: 4px;
  }
}
.pantry .nav-menu__item + .nav-menu__item--separator {
  margin-top: 12px;
}
@media (min-width: 768px) {
  .pantry .nav-menu__item + .nav-menu__item--separator {
    margin-top: 0;
    margin-left: 4px;
  }
}
.pantry .nav-menu__item--separator + .nav-menu__item,
.pantry .nav-menu__item--separator + .nav-submenu {
  margin-top: 0;
  margin-left: 0;
}
.pantry .nav-submenu,
.pantry .nav-submenu__item {
  list-style: none;
}
.pantry .nav-submenu {
  z-index: 2;
}
@media (min-width: 768px) {
  .pantry .nav-submenu {
    position: relative;
  }
}
.pantry .nav-submenu.is-open .nav-submenu__container {
  display: flex;
}
.pantry .nav-submenu.is-open .nav-submenu__title {
  background-color: #f3f5fd;
  color: #2c50ff;
}
.pantry .nav-submenu.is-open .nav-submenu__title::after {
  border-color: #4a4a4a;
}
@media (min-width: 768px) {
  .pantry .nav-submenu.is-open .nav-submenu__title::after {
    margin-top: 0.28571em;
    transform: rotate(-45deg);
  }
}
.pantry .nav-submenu__container {
  display: none;
  position: absolute;
  top: 0;
  right: -100%;
  width: 100%;
  height: auto;
  min-height: 100%;
  background-color: #ffffff;
  flex-direction: column;
}
@media (min-width: 768px) {
  .pantry .nav-submenu__container {
    flex-direction: row;
    top: auto;
    right: auto;
    left: 50%;
    width: auto;
    min-height: auto;
    margin-top: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: translateX(-50%);
  }
}
.pantry .nav-submenu__title {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.pantry .nav-submenu__title::after {
  content: " ";
  display: inline-block;
  height: 0.57143em;
  width: 0.57143em;
  margin-left: auto;
  border-style: solid;
  border-width: 2px 2px 0 0;
  border-color: #757575;
  transform: rotate(45deg);
}
@media (min-width: 768px) {
  .pantry .nav-submenu__title::after {
    margin-left: 8px;
    margin-top: -0.28571em;
    transform: rotate(135deg);
  }
}
.pantry .nav-submenu__items {
  list-style: none;
  padding: 16px;
  width: 100%;
}
@media (min-width: 768px) {
  .pantry .nav-submenu__items {
    width: 310px;
  }
}
.pantry .nav-submenu__item .btn--link {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "San Francisco", Roboto, "Segoe UI", "Helvetica Neue", sans-serif;
  color: #757575;
  font-weight: 500;
}
.pantry .nav-submenu__item .btn--link:hover {
  text-decoration: underline;
  background: none;
}
.pantry .nav-submenu__item .btn--link:lang(vi) {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "San Francisco", Roboto, "Segoe UI", "Helvetica Neue", sans-serif;
}
.pantry .nav-submenu__item .btn--link:lang(th) {
  font-family: "Inter", "Prompt", -apple-system, BlinkMacSystemFont, "San Francisco", Roboto, "Segoe UI", "Helvetica Neue", sans-serif;
}
.pantry .nav_submenu__item-icon {
  margin-top: 2px;
  margin-right: 8px;
}
.pantry .nav-submenu__item-subtitle {
  margin-bottom: 0;
  margin-top: 4px;
  color: #757575;
  font-size: 12px;
}
.pantry .nav-submenu__aside {
  padding: 24px;
  border-top: 1px solid #e0e0e0;
}
@media (min-width: 768px) {
  .pantry .nav-submenu__aside {
    width: 310px;
    border-top: 0;
    border-left: 1px solid #e0e0e0;
  }
}
.pantry .nav-submenu__item + .nav-submenu__item {
  margin-top: 4px;
}
.pantry .nav-submenu + .nav-submenu,
.pantry .nav-submenu + .nav-menu__item {
  margin-top: 4px;
}
@media (min-width: 768px) {
  .pantry .nav-submenu + .nav-submenu,
  .pantry .nav-submenu + .nav-menu__item {
    margin-top: 0;
    margin-left: 4px;
  }
}
.pantry .progress-indicator {
  display: flex;
  justify-content: space-around;
  padding: 0;
}
@media (min-width: 544px) {
  .pantry .progress-indicator {
    display: initial;
  }
}
.pantry .progress-indicator__step {
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;
  flex: 1 1 0;
  text-align: center;
  color: #cccccc;
}
@media (min-width: 544px) {
  .pantry .progress-indicator__step {
    text-align: initial;
    display: flex;
    align-items: center;
  }
}
.pantry .progress-indicator__step::before {
  position: absolute;
  background: #f1f1f2;
  height: 0.125em;
  width: 100%;
  left: -50%;
  bottom: 0.9375em;
}
@media (min-width: 544px) {
  .pantry .progress-indicator__step::before {
    height: 2em;
    width: 0.125em;
    left: 0.9375em;
    bottom: 2em;
  }
}
@media (min-width: 544px) {
  .pantry .progress-indicator__step + .progress-indicator__step {
    margin-top: 2em;
  }
}
.pantry .progress-indicator__step + .progress-indicator__step::before {
  content: " ";
}
.pantry .progress-indicator__step.is-active,
.pantry .progress-indicator__step.is-complete {
  color: #000;
}
.pantry .progress-indicator__step.is-active::before,
.pantry .progress-indicator__step.is-complete::before {
  background: currentColor;
}
.pantry .progress-indicator__step.is-active .progress-indicator__step-marker,
.pantry .progress-indicator__step.is-complete .progress-indicator__step-marker {
  color: currentColor;
}
.pantry .progress-indicator__step.is-active .progress-indicator__step-marker {
  background: #ffffff;
  box-shadow: inset 0 0 0 0.125em currentColor;
}
.pantry .progress-indicator__step.is-active .progress-indicator__step-label {
  font-weight: 600;
}
.pantry .progress-indicator__step.is-complete .progress-indicator__step-marker {
  background: currentColor;
}
.pantry .progress-indicator__step.is-complete .progress-indicator__step-marker::after {
  content: " ";
}
.pantry .progress-indicator__step-marker {
  position: relative;
  width: 2em;
  height: 2em;
  line-height: 2;
  display: inline-block;
  border-radius: 50%;
  background: #f1f1f2;
  text-align: center;
  z-index: 1;
}
.pantry .progress-indicator__step-marker::before, .pantry .progress-indicator__step-marker::after {
  position: absolute;
}
.pantry .progress-indicator__step-marker::before {
  content: " ";
  box-shadow: 0 0 0 0.25em #ffffff;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.pantry .progress-indicator__step-marker::after {
  border-bottom: 0.125em solid #ffffff;
  border-left: 0.125em solid #ffffff;
  top: 0.6em;
  left: 0.4em;
  height: 0.6em;
  width: 1.2em;
  transform: rotate(-45deg);
}
.pantry .progress-indicator__step-label {
  display: none;
}
@media (min-width: 544px) {
  .pantry .progress-indicator__step-label {
    margin-left: 1em;
    display: inline;
  }
}
.pantry .step-progress {
  display: initial;
  justify-content: space-around;
  padding: 0;
  font-size: 14px;
}
@media (min-width: 544px) {
  .pantry .step-progress--horizontal {
    display: flex;
  }
}
.pantry .step-progress__step {
  display: flex;
  align-items: center;
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;
  flex: 1 1 0;
  color: #2c50ff;
}
.pantry .step-progress__step:not(.is-active):not(.is-complete):not(.is-errored) {
  opacity: 0.25;
}
@media (min-width: 544px) {
  .step-progress--horizontal .pantry .step-progress__step {
    flex-direction: column;
    align-items: initial;
  }
}

.pantry .step-progress__step::before {
  position: absolute;
  background: #2c50ff;
  height: 24px;
  width: 4px;
  left: 8px;
  bottom: 23px;
  border-radius: 4px;
}
@media (min-width: 544px) {
  .step-progress--horizontal .pantry .step-progress__step::before {
    height: 4px;
    width: calc(100% - (28px));
    left: calc(-100% + (24px));
    top: 8px;
  }
}

.pantry .step-progress__step + .step-progress__step {
  margin-top: 28px;
}
@media (min-width: 544px) {
  .step-progress--horizontal .pantry .step-progress__step + .step-progress__step {
    margin-top: initial;
  }
}

.pantry .step-progress__step + .step-progress__step::before {
  content: " ";
}
.pantry .step-progress__step.is-errored {
  color: #cc1830;
}
.pantry .step-progress__step.is-errored .step-progress__step-marker::after {
  content: "!";
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.pantry .step-progress__step.is-complete .step-progress__step-marker::after {
  content: " ";
  position: absolute;
  border-bottom: 2.625px solid currentColor;
  border-left: 2.625px solid currentColor;
  top: 6px;
  left: 4px;
  height: 6px;
  width: 12px;
  transform: rotate(-45deg);
}
.pantry .step-progress__step-marker {
  position: relative;
  width: 20px;
  height: 20px;
  line-height: 2;
  display: inline-block;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: inset 0 0 0 1.75px currentColor;
  text-align: center;
  z-index: 1;
}
.pantry .step-progress__step-label {
  color: #4a4a4a;
  margin-left: 14px;
}
@media (min-width: 544px) {
  .step-progress--horizontal .pantry .step-progress__step-label {
    margin-left: initial;
    margin-top: 5.25px;
  }
}

.pantry .radial-progress {
  position: relative;
  flex-shrink: 0;
  top: 0.125em;
  width: 1em;
  height: 1em;
}
.pantry .radial-progress__base,
.pantry .radial-progress__progress {
  fill: none;
  stroke-linecap: round;
  stroke-width: 5.5;
}
.pantry .radial-progress__base {
  stroke: #cccccc;
}
.pantry .radial-progress__progress {
  stroke: #08c0a6;
  transition: 250ms stroke-dasharray cubic-bezier(0, 0, 0.2, 1);
}
.pantry .form-select {
  position: relative;
}
.pantry .form-select::after {
  content: " ";
  position: absolute;
  right: 16px;
  top: 50%;
  display: block;
  border-top: 5px solid #222222;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  transform: translateY(-50%);
}
.pantry select {
  appearance: none;
  display: block;
  width: 100%;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 2px;
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.12);
  padding: 8px 42px 8px 16px;
  color: #222222;
  font-size: 16px;
  line-height: 1.5;
  will-change: border-color, box-shadow;
  transition: border-color 150ms cubic-bezier(0, 0, 0.2, 1), box-shadow 150ms cubic-bezier(0, 0, 0.2, 1);
}
.pantry select:disabled {
  background: #f1f1f2;
  color: #757575;
  box-shadow: none;
  cursor: not-allowed;
}
.pantry select:focus {
  outline: 0;
  border-color: #3e7eff;
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.12), 0 0 0 1px #3e7eff;
}
.pantry .table-scrollable {
  overflow-x: auto;
}
.pantry table {
  border: 1px solid #e0e0e0;
  border-collapse: collapse;
}
.pantry thead + tbody,
.pantry tbody + tfoot {
  border-top: 1px solid #e0e0e0;
}
.pantry td,
.pantry th {
  padding: 16px;
  white-space: nowrap;
}
.pantry thead {
  text-align: left;
  font-weight: 500;
  color: #757575;
}
.pantry tbody {
  color: #222222;
}
.pantry tbody tr:hover {
  background-color: #f1f1f2;
}
.pantry tbody tr.is-active {
  background-color: #f3f5fd;
}
.pantry tr + tr {
  border-top: 1px solid #e0e0e0;
}
.pantry .form-textarea {
  display: block;
  width: 100%;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 2px;
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.12);
  padding: 8px 16px;
  color: #222222;
  font-family: inherit;
  font-size: 16px;
  line-height: 1.5;
  resize: vertical;
  will-change: border-color, box-shadow;
  transition: border-color 100ms cubic-bezier(0, 0, 0.2, 1), box-shadow 100ms cubic-bezier(0, 0, 0.2, 1);
}
.pantry .form-textarea:disabled, .pantry .form-textarea:read-only {
  background: #f1f1f2;
  color: #757575;
  box-shadow: none;
}
.pantry .form-textarea:disabled {
  cursor: not-allowed;
}
.pantry .form-textarea:focus {
  outline: 0;
  border-color: #79a6ff;
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.12), 0 0 0 1px #79a6ff;
}
.pantry .bg-warning {
  background-color: #ffb50d;
}
.pantry .bg-success {
  background-color: #08c0a6;
}
.pantry .bg-error {
  background-color: #cc1830;
}
.pantry .d-none {
  display: none;
}
.pantry .d-inline {
  display: inline;
}
.pantry .d-inline-block {
  display: inline-block;
}
.pantry .d-block {
  display: block;
}
@media (min-width: 544px) {
  .pantry .d-none-small {
    display: none;
  }
}
@media (min-width: 544px) {
  .pantry .d-inline-small {
    display: inline;
  }
}
@media (min-width: 544px) {
  .pantry .d-inline-block-small {
    display: inline-block;
  }
}
@media (min-width: 544px) {
  .pantry .d-block-small {
    display: block;
  }
}
@media (min-width: 768px) {
  .pantry .d-none-medium {
    display: none;
  }
}
@media (min-width: 768px) {
  .pantry .d-inline-medium {
    display: inline;
  }
}
@media (min-width: 768px) {
  .pantry .d-inline-block-medium {
    display: inline-block;
  }
}
@media (min-width: 768px) {
  .pantry .d-block-medium {
    display: block;
  }
}
@media (min-width: 1004px) {
  .pantry .d-none-large {
    display: none;
  }
}
@media (min-width: 1004px) {
  .pantry .d-inline-large {
    display: inline;
  }
}
@media (min-width: 1004px) {
  .pantry .d-inline-block-large {
    display: inline-block;
  }
}
@media (min-width: 1004px) {
  .pantry .d-block-large {
    display: block;
  }
}
.pantry .img-fluid {
  max-width: 100%;
  height: auto;
}
.pantry .m-0 {
  margin: 0px;
}
.pantry .mh-0 {
  margin-left: 0px;
  margin-right: 0px;
}
.pantry .mv-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}
.pantry .m-1 {
  margin: 2px;
}
.pantry .mh-1 {
  margin-left: 2px;
  margin-right: 2px;
}
.pantry .mv-1 {
  margin-top: 2px;
  margin-bottom: 2px;
}
.pantry .m-2 {
  margin: 4px;
}
.pantry .mh-2 {
  margin-left: 4px;
  margin-right: 4px;
}
.pantry .mv-2 {
  margin-top: 4px;
  margin-bottom: 4px;
}
.pantry .m-3 {
  margin: 8px;
}
.pantry .mh-3 {
  margin-left: 8px;
  margin-right: 8px;
}
.pantry .mv-3 {
  margin-top: 8px;
  margin-bottom: 8px;
}
.pantry .m-4 {
  margin: 12px;
}
.pantry .mh-4 {
  margin-left: 12px;
  margin-right: 12px;
}
.pantry .mv-4 {
  margin-top: 12px;
  margin-bottom: 12px;
}
.pantry .m-5 {
  margin: 16px;
}
.pantry .mh-5 {
  margin-left: 16px;
  margin-right: 16px;
}
.pantry .mv-5 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.pantry .m-6 {
  margin: 24px;
}
.pantry .mh-6 {
  margin-left: 24px;
  margin-right: 24px;
}
.pantry .mv-6 {
  margin-top: 24px;
  margin-bottom: 24px;
}
.pantry .m-7 {
  margin: 32px;
}
.pantry .mh-7 {
  margin-left: 32px;
  margin-right: 32px;
}
.pantry .mv-7 {
  margin-top: 32px;
  margin-bottom: 32px;
}
.pantry .m-8 {
  margin: 40px;
}
.pantry .mh-8 {
  margin-left: 40px;
  margin-right: 40px;
}
.pantry .mv-8 {
  margin-top: 40px;
  margin-bottom: 40px;
}
.pantry .m-9 {
  margin: 48px;
}
.pantry .mh-9 {
  margin-left: 48px;
  margin-right: 48px;
}
.pantry .mv-9 {
  margin-top: 48px;
  margin-bottom: 48px;
}
.pantry .p-0 {
  padding: 0px;
}
.pantry .ph-0 {
  padding-left: 0px;
  padding-right: 0px;
}
.pantry .pv-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.pantry .p-1 {
  padding: 2px;
}
.pantry .ph-1 {
  padding-left: 2px;
  padding-right: 2px;
}
.pantry .pv-1 {
  padding-top: 2px;
  padding-bottom: 2px;
}
.pantry .p-2 {
  padding: 4px;
}
.pantry .ph-2 {
  padding-left: 4px;
  padding-right: 4px;
}
.pantry .pv-2 {
  padding-top: 4px;
  padding-bottom: 4px;
}
.pantry .p-3 {
  padding: 8px;
}
.pantry .ph-3 {
  padding-left: 8px;
  padding-right: 8px;
}
.pantry .pv-3 {
  padding-top: 8px;
  padding-bottom: 8px;
}
.pantry .p-4 {
  padding: 12px;
}
.pantry .ph-4 {
  padding-left: 12px;
  padding-right: 12px;
}
.pantry .pv-4 {
  padding-top: 12px;
  padding-bottom: 12px;
}
.pantry .p-5 {
  padding: 16px;
}
.pantry .ph-5 {
  padding-left: 16px;
  padding-right: 16px;
}
.pantry .pv-5 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.pantry .p-6 {
  padding: 24px;
}
.pantry .ph-6 {
  padding-left: 24px;
  padding-right: 24px;
}
.pantry .pv-6 {
  padding-top: 24px;
  padding-bottom: 24px;
}
.pantry .p-7 {
  padding: 32px;
}
.pantry .ph-7 {
  padding-left: 32px;
  padding-right: 32px;
}
.pantry .pv-7 {
  padding-top: 32px;
  padding-bottom: 32px;
}
.pantry .p-8 {
  padding: 40px;
}
.pantry .ph-8 {
  padding-left: 40px;
  padding-right: 40px;
}
.pantry .pv-8 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.pantry .p-9 {
  padding: 48px;
}
.pantry .ph-9 {
  padding-left: 48px;
  padding-right: 48px;
}
.pantry .pv-9 {
  padding-top: 48px;
  padding-bottom: 48px;
}
.pantry .text-left {
  text-align: left;
}
.pantry .text-center {
  text-align: center;
}
.pantry .text-right {
  text-align: right;
}
@media (min-width: 544px) {
  .pantry .text-left--small {
    text-align: left;
  }
}
@media (min-width: 544px) {
  .pantry .text-center--small {
    text-align: center;
  }
}
@media (min-width: 544px) {
  .pantry .text-right--small {
    text-align: right;
  }
}
@media (min-width: 545px) and (max-width: 768px) {
  .pantry .text-left--small-only {
    text-align: left;
  }
}
@media (min-width: 545px) and (max-width: 768px) {
  .pantry .text-center--small-only {
    text-align: center;
  }
}
@media (min-width: 545px) and (max-width: 768px) {
  .pantry .text-right--small-only {
    text-align: right;
  }
}
@media (min-width: 768px) {
  .pantry .text-left--medium {
    text-align: left;
  }
}
@media (min-width: 768px) {
  .pantry .text-center--medium {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .pantry .text-right--medium {
    text-align: right;
  }
}
@media (min-width: 769px) and (max-width: 1004px) {
  .pantry .text-left--medium-only {
    text-align: left;
  }
}
@media (min-width: 769px) and (max-width: 1004px) {
  .pantry .text-center--medium-only {
    text-align: center;
  }
}
@media (min-width: 769px) and (max-width: 1004px) {
  .pantry .text-right--medium-only {
    text-align: right;
  }
}
@media (min-width: 1004px) {
  .pantry .text-left--large {
    text-align: left;
  }
}
@media (min-width: 1004px) {
  .pantry .text-center--large {
    text-align: center;
  }
}
@media (min-width: 1004px) {
  .pantry .text-right--large {
    text-align: right;
  }
}
.pantry .t-white {
  color: #ffffff;
}
.pantry .t-weight-normal {
  font-weight: 400;
}
.pantry .t-weight-medium {
  font-weight: 500;
}