@use 'tailwindcss/tailwind';
@use '@fundingasiagroup/toolkit-ui/styles' as toolkit-ui;
/* You can add global styles to this file, and also import other style files */
@import '@ng-select/ng-select/themes/default.theme.css';
/* CDK */
@import '@angular/cdk/overlay-prebuilt.css';

.cdk-overlay-container {
  z-index: 3000;
}

.cdk-global-scrollblock {
  background-color: white;
  top: 0 !important;
}
/* primeng datatable css reset */
.ui-datatable-tablewrapper table {
  table-layout: fixed;
  font-family: 'open sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px !important;
}

.ui-datatable-tablewrapper td,
.ui-state-default {
  padding: 8px !important;
}

.ui-state-default {
  background: none !important;
  background-color: #f5f5f6 !important;
  border: 1px solid #ebebeb !important;
  border-bottom: 1px solid #dddddd !important;
}

.ui-paginator-rpp-options {
  float: right;
}

/**/

.table-row:hover {
  cursor: pointer;
  background-color: #dfdfdf !important;
}

.sort-toggle {
  cursor: pointer;
  margin-top: 0.25em;
}

.sort-active {
  color: #1ab394;
}

.fa.fa-eye:hover,
.fa.fa-check-square-o:hover {
  cursor: pointer;
  color: #1ab394;
}

.form-validation {
  margin-top: 0.5em;
  color: red;
  font-style: italic;
}

.filter-heading {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-bottom: 0.5em;
  text-align: left;
  font-weight: bold;
}

.filter-item {
  position: relative;
  float: left;
  clear: both;
  width: auto;
  height: auto;
  padding: 0.5em 0.75em;
  margin-bottom: 0.5em;
  text-align: left;
  border: 1px solid lightgray;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  cursor: pointer;
}

.filter-selected {
  background-color: #337ab7 !important;
  color: white !important;
}

.ui-growl {
  width: 40% !important;
  margin-left: 30% !important;
  margin-right: 30% !important;
  left: 0 !important;
  color: white !important;
}

.ui-growl-image {
  display: none !important;
}

.ui-growl-message {
  width: 100% !important;
  text-align: center !important;
  padding: 0 1.5em !important;
  box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  -webkit-box-sizing: border-box !important;
}

.ui-growl-icon-close {
  top: 0.85em !important;
  right: 0.85em !important;
}

.ui-growl-title {
  display: none !important;
}

.ui-growl-message-info {
  background-image: none !important;
  background-color: #2196f3 !important;
  border-color: #2196f3 !important;
}

.ui-growl-message-success {
  background-image: none !important;
  background-color: #4caf50 !important;
  border-color: #4caf50 !important;
}

.ui-growl-message-warn {
  background-image: none !important;
  background-color: #ffb300 !important;
  border-color: #ffb300 !important;
}

.ui-growl-message-error {
  background-image: none !important;
  background-color: #f44336 !important;
  border-color: #f44336 !important;
}

/*css for ui fix*/
.table > tfoot > tr > td {
  padding: 0px 4px !important;
  font-weight: bold;
}

.float-e-margins .btn {
  margin: 0px;
}

.form-group {
  overflow: hidden;
  margin-bottom: 5px;
}

.form-control {
  padding: 3px 6px;
  height: 26px;
  border: 1px solid #888;
}

.agile-list li {
  padding: 3px 6px;
}

tr.contenteditable.true {
  background-color: #f00;
}

.roll {
  background-color: #c9fff2;
}

.form-group {
  margin-bottom: 4px;
}

/*end css for ui fix*/
.ui-datepicker {
  width: inherit !important;
  position: absolute !important;
}

.ui-calendar {
  display: block !important;
}

.ui-inputtext {
  width: 100% !important;
  border-radius: 0px !important;
  padding: 2px 6px !important;
  height: 28px !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.ui-state-default {
  background: none !important;
}

.ui-widget-content {
  box-sizing: inherit !important;
}

.ui-state-default,
.ui-state-highlight {
  color: inherit !important;
}

.form-group {
  overflow: inherit;
}

.form-control {
  padding: 2px 6px !important;
}

input,
select {
  height: 28px !important;
}

input[type='checkbox'] {
  height: initial !important;
}

.hr-line-dashed {
  margin: 10px 0px !important;
}

.ui-widget-overlay {
  z-index: 99999 !important;
}

.ui-dialog {
  z-index: 100000 !important;
}

.ui-tabview-nav,
.ui-helper-reset,
.ui-helper-clearfix,
.ui-widget-header,
.ui-corner-all {
  border-bottom: none !important;
}

.ui-tabview-panels {
  border: 1px solid #ebebeb !important;
}

.ui-state-default a,
.ui-corner-top a,
.ui-tabview-selected a,
.ui-state-active a {
  font-weight: 600 !important;
  color: #555555 !important;
}

.ui-tabview-selected a,
.ui-state-active a {
  font-weight: bold !important;
}

.ui-inputtext {
  border-bottom: 1px solid #ebebeb !important;
}

.docImg {
  width: 100px !important;
  height: 100px !important;
  margin: 2px;
}

.b-separator {
  border-left: 1px #ebebeb solid;
  margin-right: 3px;
}

td,
th {
  font-family: inherit;
  font-size: inherit;
}

.b-el {
  padding: 3px;
  background: #fafafb;
  border: 1px solid #e7eaec;
  margin: 0 0 6px 0;
  border-radius: 2px;
}

.b-i {
  border-left: 3px solid #1c84c6;
}

.b-s {
  border-left: 3px solid #1ab394;
}

.b-d {
  border-left: 3px solid #ed5565;
}

.b-w {
  border-left: 3px solid #f8ac59;
}

.b-btn {
  margin-bottom: 0px !important;
  padding: 2px 4px;
  font-size: 13px !important;
}

.ibox-content {
  display: block !important;
}

.s-me {
  background-color: #337ab7 !important;
  color: #fff !important;
}

.fs_loading {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #e8e8e8 no-repeat url('./assets/images/loading_fs.png') center center scroll;
}

.fs_loading::before {
  position: absolute;
  content: '';
  background: no-repeat url('./assets/images/loading_fs_progress.png') center center scroll;
  height: 100px;
  width: 100px;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-size: contain;
  animation: spin 0.9s linear infinite;
  margin: 0;
  z-index: 999;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.environmentBox {
  padding: 7px 35px 8px !important;
  font-weight: bold;
  opacity: 0.7;
  width: 100%;
  margin: 0 !important;
}

/* bootstrap 4.0 */
/* https://getbootstrap.com/docs/4.0/components/forms/#readonly-plain-text */
.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

/* NgbTypeahead */
.dropdown-item {
  display: block !important;
  width: 100% !important;
  padding: 0.5rem 1.5rem !important;
  clear: both !important;
  font-weight: 400 !important;
  text-align: inherit !important;
  white-space: nowrap !important;
  background-color: transparent !important;
  border: 0 !important;
}

.form .form-group {
  margin-bottom: 30px;
}

.form .form-group .control-label {
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
}

.form .form-group .form-control:not(textarea) {
  padding: 6px 12px !important;
  height: 34px !important;
}

.form .form-group textarea.form-control {
  padding: 6px 12px !important;
  height: auto !important;
}

.form .form-group[required] label.control-label {
  position: relative;
}

.form .form-group[required] label.control-label::after {
  content: ' * ';
  color: #ed5565;
  display: block;
  position: absolute;
  right: -10px;
  top: 0;
}

/* Modal */
.modal h1,
.modal h2,
.modal h3,
.modal h4,
.modal h5,
.modal h6 {
  font-weight: bold;
  margin-bottom: 15px;
}

.modal .modal-header {
  border-bottom: 0;
}

.modal .modal-body {
  border-bottom: 0;
}

.modal .modal-footer {
  border-top: 0;
}

.form .form-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.glyphicon:before {
  font-family: 'Glyphicons Halflings', sans-serif !important;
}
/* Modified styles for searchable dropdown ng-select*/

.ng-select-container.ng-has-value {
  border-color: rgba(229, 230, 231, 1);
}

.ng-select.ng-select-container {
  border-color: rgba(229, 230, 231, 1);
}

.ng-value-container {
  color: rgba(103, 106, 108, 1);
}

.ng-select.ng-select-opened > .ng-select-container {
  background: #fff;
  border-color: #28b294;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: #28b294;
  box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 3px rgba(0, 179, 128, 0.1);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
  cursor: default;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
  background-color: #e1f8f3;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked {
  background-color: #e1f8f3;
  font-weight: 600;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #fff;
  color: rgba(103, 106, 108, 0.87);
  padding: 8px 10px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  background-color: #e1f8f3;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #e1f8f3;
}

.p-calendar {
  width: 100%;
}

.p-calendar .p-inputtext {
  font-size: 1em !important;
  color: inherit;
  border-radius: 1px;
  border: 1px solid rgb(229, 230, 231);
  display: block;
}

.p-inputtext {
  font-size: 12px !important;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-center {
  align-items: center;
}

.external-link {
  width: 20px;
  height: 20px;
  margin-left: 4px;
  cursor: pointer;
}

.link-text {
  color: #7476b4;
  text-decoration: underline;
}

.ngselect-form-control-error .ng-select-container {
  border-color: red !important;
  color: red !important;
}

.m-1 {
  margin: 1px;
}

.float-e-margins .m-1 {
  margin: 1px !important;
}

//#region toolkit-ui overrides
@include toolkit-ui.settings-all();
.fsw-field.disabled {
  background-color: unset !important;
  border: unset !important;
}

//#endregion
