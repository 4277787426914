.ag-theme-alpine .ag-label {
  text-align: center;
  text-transform: uppercase;
  padding: 2px 4px;
  min-width: 50px;
  width: auto;
  border-radius: 4px;
}

span.ag-label.ag-label--green {
  background-color: #dcfaf0;
  color: #1f9989;
}

span.ag-label.ag-label--yellow {
  background-color: #fff3d6;
  color: #b85716;
}

span.ag-label.ag-label--gray {
  background-color: #f1f1f2;
  color: #757575;
}

span.ag-label.ag-label--file {
  background-color: #f3f5fd;
  color: #2c50ff;
  padding: 5px;
}

.ag-theme-alpine .ag-row-selected {
  background-color: transparent;
}

.ag-theme-alpine .ag-row-hover {
  background-color: #F6F6F6;
}

.ag-theme-alpine .ag-row-odd {
  background-color: transparent;
}

.ag-theme-alpine .ag-cell {
  color: #4a4a4a;
}

.ag-theme-alpine .ag-cell .item {
  word-break: break-word;
  line-height: 1.5;
}

.ag-theme-alpine .ag-row {
  display: flex;
  align-items: center;
}

.ag-theme-alpine .ag-header-container {
  background: #fff;
  min-width: 100% !important;
}
.ag-theme-alpine .ag-header-cell-text {
  color: #757575;
  font-weight: 500;
}

.ag-theme-alpine .ag-center-cols-container {
  min-width: 100% !important;
}

.ag-theme-alpine .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
  border-color: transparent;
}

.ag-theme-alpine .ag-ltr .ag-selection-checkbox {
  margin-right: 20px;
}
